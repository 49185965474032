import { forwardRef } from "react";
import { createPortal } from "react-dom";
import savedImage from "../assets/saved.jpg";
const SavingModal = forwardRef(function SavingModal({ isOpen }, ref) {
  return (
    <>
      {isOpen &&
        createPortal(
          <dialog ref={ref} className="result-modal">
            <img src={savedImage} />
            <h3>Question Saved Successfully</h3>
          </dialog>,
          document.getElementById("saving-question-modal")
        )}
    </>
  );
});

export default SavingModal;
