import Header from './Components/Header';
import Question from './Components/Question';

function App() {
  return (<>
  <Header/>
<Question/>
  </>
  );
}

export default App;
