import { forwardRef } from "react";

const OptionInput = forwardRef(function OptionInput(
  { inputId, labelText, inputType, max },
  inputRef
) {
  return (
    <>
      <label htmlFor={inputId}>{labelText}</label>
      {max ? (
        <input ref={inputRef} id={inputId} type={inputType} max={max} />
      ) : (
        <input ref={inputRef} id={inputId} type={inputType} />
      )}
    </>
  );
});

export default OptionInput;
