import { forwardRef } from "react";

const AnswerSelectionDropBox = forwardRef(function (props, correctAnswerRef) {
  return (
    <div className="custom-select" >
      <label htmlFor="correct-answer">Correct Answer</label>
      <select defaultValue="Select An Answer" className="answerDropDown" id="correct-answer" ref={correctAnswerRef}>
        {props.answerList.map((option,index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
});

export default AnswerSelectionDropBox;
